import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { Subject, takeUntil } from 'rxjs';

@Directive({
    selector: '[clearable]',
    standalone: true,
})
export class ClearableInputDirective implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();
    private clearButton: HTMLElement;

    constructor(private matInput: MatInput, private el: ElementRef, private renderer: Renderer2) {
        this.matInput.stateChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.toggleClearButtonVisibility();
        });
    }

    ngOnInit() {
        this.createClearButton();
    }

    private createClearButton() {
        this.clearButton = this.renderer.createElement('button');
        this.renderer.addClass(this.clearButton, 'btn-close');
        this.renderer.setStyle(this.clearButton, 'position', 'absolute');
        this.renderer.setStyle(this.clearButton, 'right', '3px');
        this.renderer.setStyle(this.clearButton, 'top', '0');
        this.renderer.setStyle(this.clearButton, 'display', 'none');
        this.renderer.listen(this.clearButton, 'click', () => this.clearInput());
        this.el.nativeElement.parentNode.insertBefore(this.clearButton, this.el.nativeElement.nextSibling);
    }

    private clearInput() {
        this.matInput.value = '';
        this.triggerInputChange();
    }

    private triggerInputChange() {
        this.el.nativeElement.closest('mat-form-field').querySelector('input')?.dispatchEvent(new KeyboardEvent('keyup'));
    }

    private toggleClearButtonVisibility() {
        if (this.clearButton) {
            const hasValue = !!this.matInput.value;
            this.renderer.setStyle(this.clearButton, 'display', hasValue ? 'inline' : 'none');
            this.renderer.setStyle(this.el.nativeElement, 'width', hasValue ? 'calc(100% - 25px)' : '100%');
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
